<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >

    <v-row>
      <v-col
      cols="12"
      sm="12"
      lg="12"
      >
          <base-material-card
          color="brown"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div>
              <div class="white--text">{{ timestamp }}</div>
            </div>

          </template>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4">
                <div class="px-5 py-5 red lighten-5 rounded-5">
                <div class="subtitle-2"><span class="font-weight-light"> {{ greeting }} Bpk/Ibu.</span> <span class="font-weight-bold">{{ dataUser.fullname}}</span></div>
                <div class="subtitle-2"><span class="font-weight-light">Anda Menjabat sebagai: </span> <span class="font-weight-bold">{{ Jabatan }}</span></div>
                </div>
                </v-col>
                <v-col cols="12" md="5">
                  <div class="px-5 py-5 yellow lighten-5 rounded-5 subtitle-2">
                    Anda memiliki Role aktif sebagai berikut:
                    <ul>
                      <li v-for="(n, i) in roleItems" :key="i">
                        <v-btn style="height:20px;" text class="blue--text" small @click="changeRole(n.value)">
                          <span v-if="n.hasnewLetter"><b>{{n.text}} <span class="red--text">*</span></b></span>
                          <span v-else>{{n.text}}</span>
                        </v-btn>
                      </li>
                    </ul>
                  </div>
                </v-col>
                <v-col cols="12" md="3" >
                <div class="blue lighten-5 rounded-5">
                  <v-card-text>
                    <v-icon>mdi-phone</v-icon>
                    <span class="subtitle-2 font-weight-bold">
                      Buku Telepon
                    </span>
                    <v-autocomplete
                      v-model="pDeskID"
                      :items="dataListUser"
                      label="Cari Nama"
                      item-text="fullname"
                      item-value="id"
                      @change="searchPhone"
                    ></v-autocomplete>
                  <span v-html="phoneChoosen"></span>
                  </v-card-text>
                </div>
              </v-col>
              </v-row>
              <hr>
              <v-row>
              <v-col cols="12" md="12">
                
              <v-tabs
                v-model="newsTab"
              >
                <v-tabs-slider color="orange"></v-tabs-slider>
                <v-tab v-for="item in newsitems" :key="item">
                  {{ item }}
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="newsTab">
                
                <v-tab-item
                  key="1"
                >
                  <div class="px-5 py-5 subtitle-2 font-weight-light green lighten-5">
                    <AsyncDashboardInfo/>
                  </div>
                </v-tab-item>
                <v-tab-item
                  key="2"
                >
                  <div class="px-5 py-5 subtitle-2 font-weight-light green lighten-5">
                    <AsyncWebsite/>
                  </div>
                </v-tab-item>
                <v-tab-item
                  key="3"
                >
                  <div class="px-5 py-5 subtitle-2 font-weight-light green lighten-5">
                    <AsyncCalendarst/>
                  </div>
                </v-tab-item> 
                
              </v-tabs-items>
              </v-col>
              
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-col>

        <v-col
          cols="12"
          sm="6"
          lg="4"
        >
          <base-material-stats-card
            color="red"
            icon="mdi-clipboard-text"
            title="DISPOSISI"
            valcolor="black--text"
            :value="vChart.dispo"
            sub-icon="mdi-eye"
            sub-text="<a href='#/inbox/0'>Klik disini untuk membuka data</a>"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          lg="4"
        >
          <base-material-stats-card
            color="info"
            icon="mdi-mail"
            title="PERSETUJUAN/ TTD"
            valcolor="red--text"
            :value="vChart.ttd"
            sub-icon="mdi-eye"
            sub-text="<a href='#/inbox/1'>Klik disini untuk membuka data</a>"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          lg="4"
        >
          <base-material-stats-card
            color="success"
            icon="mdi-share"
            title="SHARE"
            :value="vChart.share"
            valcolor="black--text"
            sub-icon="mdi-eye"
            sub-text="<a href='#/inbox/2'>Klik disini untuk membuka data</a>"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          lg="4"
        >
          <base-material-stats-card
            color="orange"
            icon="mdi-forum"
            title="KOMENTAR"
            valcolor="black--text"
            :value="vChart.comment"
            sub-icon="mdi-eye"
            sub-text="<a href='#/inbox/3'>Klik disini untuk membuka data</a>"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          lg="4"
        >
          <base-material-stats-card
            color="grey"
            icon="mdi-pencil-box-outline"
            title="DRAFT"
            valcolor="black--text"
            :value="vChart.draft"
            sub-icon="mdi-eye"
            sub-text="<a href='#/case/list/1'>Klik disini untuk membuka data</a>"
          />
        </v-col>

    </v-row>

    
  </v-container>
</template>

<script>

  import moment from 'moment'
  import { forEach } from 'p-iteration'
  import secureStorage from '@/utils/secureStorage'
  import { getUserPosRoles } from '@/api/positionRoles'
  import { countSMDisposisiHasDispo, countSMTTD, countSMShareHasDispo, countSMComment, getNewLetterRole   } from '@/api/letterinout'
  import { countSKDraft } from '@/api/datasurat'
  import { getListPositionsActive } from '@/api/hrpositions'

  import kalenderST from "../components/kalenderST";
  import rssWebsite from "../components/rssWebsite";
  import dashboardInfo from "../components/dashboardInfo";

  const AsyncCalendarst = () => ({
    component: new Promise(resolve => {
      setTimeout(() => {
        resolve(kalenderST);
      }, 1000);
    }),
    delay: 0,
    timeout: 2000
  });

  const AsyncWebsite = () => ({
    component: new Promise(resolve => {
      setTimeout(() => {
        resolve(rssWebsite);
      }, 1000);
    }),
    delay: 0,
    timeout: 2000
  });

  const AsyncDashboardInfo = () => ({
    component: new Promise(resolve => {
      setTimeout(() => {
        resolve(dashboardInfo);
      }, 1000);
    }),
    delay: 0,
    timeout: 2000
  });

  export default {
    name: 'direksiDashboard',
    components: { AsyncCalendarst, AsyncWebsite, AsyncDashboardInfo },
    data () {
      return {
        vtimer: null,
        vtimeRefreshData: null,
        timestamp: '',
        greeting: '',
        vChart: {
          dispo: '0',
          ttd: '0',
          share: '0',
          comment: '0',
          draft: '0'
        },
        dataUser: null,
        Jabatan: '-',
        pDeskID: 0,
        dataListUser:[],
        phoneChoosen: '',
        roleItems: [],
        selRole: 0,
        userpos: [],
        newsTab: null,
        newLetterinfos: [],
        newsitems: [
           'Internal News', 'Websites News', 'Kalender'
        ],
        
        
      }
    },
    computed: {
      computedValue: function(){
          return this.timestamp;
      }
    },
    filters: {
      formatDate: function (value) {
        if (!value) return ''
        var sdate = moment(value).add(7, 'hours').format('DD MMM YYYY')
        return sdate
      },
      formatDatetime: function (value) {
        if (!value) return ''
        var sdate = moment(value).add(7, 'hours').format('DD/MM/YYYY HH:mm')
        return sdate
      }
    },
    destroyed () {
      clearInterval(this.vtimer)
      clearInterval(this.vtimeRefreshData)
    },
    created () {
      const vm = this
      vm.vtimer = setInterval(function(){
        vm.timestamp = moment().format('DD MMM YYYY HH:mm:ss')
      }, 1000)

      vm.dataUser = secureStorage.getItem('user')
      // console.log(vm.dataUser)
      vm.isAdmin = false
      if (vm.dataUser !== null) {
        vm.userNIK = vm.dataUser.nik
        if(vm.dataUser.role !== null) {
          if(vm.dataUser.role.id === 1) {
            vm.isAdmin = true
          }
        }
      }

      const crole = secureStorage.getItem('currRole')
      // console.log('crole',crole)
      if(crole){
        var pref = crole.prefix != '' ? crole.prefix + ' ' : ''
        vm.Jabatan = pref + crole.hrposition.pos_name

        if(crole.hrorganization){
          var res= crole.hrorganization.unit_code.toString() 
          vm.unitCode  = res.substring(0, 5);
        }
        console.log('unitCode', vm.unitCode)
      }

      vm.getlistPositionUpdate()
      vm.greetingText()
      vm.getSMData() 
      vm.getSKDraft()
      
    },
    mounted () {
      const vm = this
      vm.vtimeRefreshData = setInterval(function(){
        // console.log('refresh data')
        vm.getSMData()
      }, 60000)

      const alluser = secureStorage.getItem('userall')
      if(alluser){
        vm.dataListUser = alluser
      }

      vm.userpos = secureStorage.getItem('userpos')
      if(!vm.userpos){
        getUserPosRoles(token, vm.user.id).then(response => {
          vm.userpos = response.data
          secureStorage.setItem('userpos',response.data)
          vm.getNewLetterOtherRole()
        }).catch(error => {
          // console.log(error)
        })
      } 
      else {
        vm.getNewLetterOtherRole()
      }

    },

    methods: {
      greetingText() {
        const now = moment()
        const currentHour = now.hour()
        if (currentHour >= 17 && currentHour <= 24) {
          this.greeting = 'Selamat Malam, '
        } else if (currentHour >= 14 && currentHour <= 17) {
          this.greeting = 'Selamat Sore, '
        } else if (currentHour <= 18) {
          this.greeting = 'Selamat Siang, '
        } else {
          this.greeting = 'Selamat Pagi, '
        }
      },
      parseRole () {
        const vm = this
        
        var currrole = secureStorage.getItem('currRole')
        vm.selRole = 0
        if (currrole){
          vm.selRole = currrole.id
        }
        if (vm.userpos) {
          vm.roleItems = []
          var listrole = []
          vm.userpos.forEach (element => {
            if (element.hrposition) {
              var prefix = ''
              if(element.prefix == null){
                prefix = ''
              }else{
                prefix = element.prefix + ' '
              }

              var holding = ''
              if(element.hrorganization.holding == null){
                holding = ''
              }else{
                holding = ' [' + element.hrorganization.holding + ']'
              }

              var isBold = false
              try {
                if(vm.newLetterinfos && vm.newLetterinfos.length > 0){
                  vm.newLetterinfos.forEach (el => {
                    // console.log("newletter: ", el)
                    if(el.hruserpositions_to == element.id){
                      isBold = true
                    }
                  })
                }
              } catch (error) {
                console.log('newLetterinfos', error)
              }

              var tmp = {value: element.id, text: prefix + element.hrposition.pos_name , hd:holding, ucode: element.hrorganization.unit_code, hasnewLetter: isBold}
              listrole.push(tmp)
            }
          })

          var sortedrole = listrole.sort((a, b) => (a.ucode > b.ucode ? 1 : -1))
          vm.roleItems = sortedrole
          // console.log('roles ', vm.roleItems)
        }
      },
      changeRole (idROle) {
         // console.log('changeRole ',arrCurrRole)
        var arrCurrRole = this.userpos.find(x => x.id === idROle)
       
        secureStorage.setItem('currRole', arrCurrRole)
        this.isLoading = true
        setTimeout(() => location.reload(), 1000)
        
      },
      getlistPositionUpdate(){
        const vm = this
        vm.isLoading = true
        getListPositionsActive().then(response => {
          secureStorage.setItem('posall',response.data)

          vm.isLoading = false
        }).catch(error => {
          // console.log(error)
          vm.isLoading = false
        })
      },
      
      getSMData(){
        const vm = this

        const crole = secureStorage.getItem('currRole')
        var idpos = crole.hrposition.id
        var iduserpos = crole.id
        var isHead = crole.hrposition.is_headunit

        countSMDisposisiHasDispo(iduserpos, idpos, isHead).then(response => {
          var data = response.data
          // console.log('countSMDisposisi', data)
          // vm.vChart.dispo = data.toString()

          console.log('countSMDisposisiHasDispo', data[0].total)
          vm.vChart.dispo = data[0].total.toString()
        })
        vm.getListTTD()
        countSMShareHasDispo(iduserpos, idpos, isHead).then(response => {
          var data = response.data
          // vm.vChart.share = data.toString()
          console.log('countSMShareHasDispo', data[0].total)
          vm.vChart.share = data[0].total.toString()
        })
        countSMComment(crole.id).then(response => {
          var data = response.data
          vm.vChart.comment = data.toString()
        })
        
        // console.log(vm.vChart)
      },
      
      getSKDraft () {
        const vm = this
        var idparam = 0
        const crole = secureStorage.getItem('currRole')
        idparam = crole.id
        var listQuery = {
          search: ''
        }
        countSKDraft(listQuery, idparam).then(response => {
          var data = response.data
          // console.log('countSKDraft', data)
          if (data) {
            vm.vChart.draft = data.toString()
          }
          vm.isLoading = false
        }).catch(err => {
          // console.log(err)
          vm.isLoading = false
        })
      },
      getNewLetterOtherRole () {
        const vm = this
       // console.log(vm.dataUser)
        getNewLetterRole(vm.dataUser.id).then(response => {
          var data = response.data
          //console.log('getNewLetterRole', data)
          vm.newLetterinfos = data
          vm.isLoading = false

          vm.parseRole ()
        }).catch(err => {
          // console.log(err)
          vm.isLoading = false
          vm.parseRole ()
        })
      },
      getListTTD () {
        const vm = this
        var idparam = 0
        const crole = secureStorage.getItem('currRole')
        if(crole.hrposition.is_headunit){
          idparam = crole.hrposition.id
        }else{
          idparam = crole.id
        }

        countSMTTD(idparam, crole.hrposition.is_headunit).then(response => {
          var data = response.data
          console.log('countSMTTD', data)
          if (data) {
            vm.vChart.ttd = data[0]['total'].toString()
          }
          vm.isLoading = false
        }).catch(err => {
          // console.log(err)
          vm.isLoading = false
        })
      },
      complete (index) {
        this.list[index] = !this.list[index]
      },
      searchPhone() {
        //watch you code here

        var data = this.dataListUser.find(x => x.id === this.pDeskID)
        if(data){
          this.pDeskID = ''
          var desk = data.phoneDesk ? data.phoneDesk : '-'
          var mob = data.phoneNumber ? data.phoneNumber : '-'
          this.phoneChoosen = '<b>'+data.fullname+'</b><br><ul><li class="subtitle-2"><i class="mdi mdi-phone-classic"></i>&nbsp;'+desk+'</li><li class="subtitle-2"><i class="mdi mdi-cellphone-basic"></i>&nbsp;'+mob+'</li></ul>'
        }
      },
      
    },

  }
</script>

<style>

</style>