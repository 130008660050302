import request from '@/utils/request'
import { _GLOBAL } from '@/api/server'
import secureStorage from '@/utils/secureStorage'

/** API Inbox/Outbox */

export function getSMByID(id) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/letterinouts?id=' + id
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getSMImportant(myuserpos) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/letterinouts?hruserpositions_to=' + myuserpos + '&to_isImportant=1&deleted_to=0&_limit=30&_sort=id%3ADESC'
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}


export function getSMDisposisi(myuserpos, isHead) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  
  var uri = _GLOBAL.URLBACKEND + '/letterinouts?hruserpositions_to=' + myuserpos + '&to_isOpen=0&eostatus_to=25&deleted_to=0&_limit=30&_sort=id%3ADESC'
  if(isHead){
    uri = _GLOBAL.URLBACKEND + '/letterinouts?hrpositions_to=' + myuserpos + '&to_isOpen=0&eostatus_to=25&deleted_to=0&_limit=30&_sort=id%3ADESC'
  }

  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getSMDisposisiHasDispo(myuserpos, mypos, isHead) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  
  var uri = _GLOBAL.URLBACKEND + '/letterinouts/f/dispo?hrpositions_to=' + mypos + '&hruserpositions_to=' + myuserpos + '&isHead=' + isHead

  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function countSMDisposisi(myuserpos, isHead) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''
  
  var uri = _GLOBAL.URLBACKEND + '/letterinouts/count?hruserpositions_to=' + myuserpos + '&to_isOpen=0&deleted_to=0&eostatus_to=25'
  if(isHead){
    uri = _GLOBAL.URLBACKEND + '/letterinouts/count?hrpositions_to=' + myuserpos + '&to_isOpen=0&deleted_to=0&eostatus_to=25'
  }
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function countSMDisposisiHasDispo(myuserpos, mypos, isHead) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''
  
  var uri = _GLOBAL.URLBACKEND + '/letterinouts/c/dispo?hrpositions_to=' + mypos + '&hruserpositions_to=' + myuserpos + '&isHead=' + isHead

  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}


export function getViewSuratSMDisposisi(idsurat) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  
  var uri = _GLOBAL.URLBACKEND + '/letterinouts?id_letter=' + idsurat + '&eostatus_to=25&deleted_to=0&_limit=30&_sort=id%3ADESC'

  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getSMTTD(myuserpos, isHead) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''
  var uri = _GLOBAL.URLBACKEND + '/letterinouts/f/ttd?parameter=' + myuserpos + '&isHead=' + isHead

  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function countSMTTD(myuserpos, isHead) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''
  var uri = _GLOBAL.URLBACKEND + '/letterinouts/c/ttd?parameter=' + myuserpos + '&isHead=' + isHead
  
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}


export function getViewSuratSMTTDSurat(idsurat) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  
  var uri = _GLOBAL.URLBACKEND + '/letterinouts?id_letter=' + idsurat + '&eostatus_to=26&deleted_to=0&_limit=30&_sort=id%3ADESC'

  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getSMShare(myuserpos) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/letterinouts?hruserpositions_to=' + myuserpos + '&to_isOpen=0&deleted_to=0&eostatus_to=27&_limit=30&_sort=id%3ADESC'
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}


export function countSMShare(myuserpos) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''
  
  var uri = _GLOBAL.URLBACKEND + '/letterinouts/count?hruserpositions_to=' + myuserpos + '&to_isOpen=0&deleted_to=0&eostatus_to=27'
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getSMShareHasDispo(myuserpos, mypos, isHead) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/letterinouts/f/share?hrpositions_to=' + mypos + '&hruserpositions_to=' + myuserpos + '&isHead=' + isHead
  // var uri = _GLOBAL.URLBACKEND + '/letterinouts?hruserpositions_to=' + myuserpos + '&hasDispo=0&deleted_to=0&eostatus_to=27&_limit=30&_sort=id%3ADESC'
  
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function countSMShareHasDispo(myuserpos, mypos, isHead) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''
  
  var uri = _GLOBAL.URLBACKEND + '/letterinouts/c/share?hrpositions_to=' + mypos + '&hruserpositions_to=' + myuserpos + '&isHead=' + isHead
  //var uri = _GLOBAL.URLBACKEND + '/letterinouts/count?hruserpositions_to=' + myuserpos + '&hasDispo=0&deleted_to=0&eostatus_to=27'
  
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getViewSuratSMShare(idsurat) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  
  var uri = _GLOBAL.URLBACKEND + '/letterinouts?id_letter=' + idsurat + '&eostatus_to=27&deleted_to=0&_limit=1000&_sort=id%3ADESC'
  var data = ''
  console.log(uri)
  
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}


export function getSMComment(myuserpos) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/letterinouts?hruserpositions_to=' + myuserpos + '&to_isOpen=0&deleted_to=0&eostatus_to=28&_limit=30&_sort=id%3ADESC'
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function countSMComment(myuserpos) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''
  
  var uri = _GLOBAL.URLBACKEND + '/letterinouts/count?hruserpositions_to=' + myuserpos + '&to_isOpen=0&deleted_to=0&eostatus_to=28'
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getViewSuratSMComment(idsurat) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  
  var uri = _GLOBAL.URLBACKEND + '/letterinouts?id_letter=' + idsurat + '&eostatus_to=28&deleted_to=0&_limit=30&_sort=id%3ADESC'
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getSMAllOpened(listQuery, myuserpos) {

  // console.log(listQuery)
  var page = listQuery.page
  var limit = listQuery.limit
  var search = listQuery.search
  // console.log(limit)

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var start = (page - 1) * limit
  if (start < 0) start = 0

  var uri = _GLOBAL.URLBACKEND + '/letterinouts?subject_contains=' + search + '&hruserpositions_to=' + myuserpos + '&deleted_to=0&_limit=' + limit + '&_start=' + start +'&_sort=id%3ADESC'
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function countSMAllOpened(listQuery, myuserpos) {
  var search = listQuery.search

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''
  
  var uri = _GLOBAL.URLBACKEND + '/letterinouts/count?subject_contains=' + search + '&deleted_to=0&hruserpositions_to=' + myuserpos
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getSKAllOpened(listQuery, myuserpos) {

  // console.log(listQuery)
  var page = listQuery.page
  var limit = listQuery.limit
  var search = listQuery.search
  // console.log(limit)

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var start = (page - 1) * limit
  if (start < 0) start = 0

  var uri = _GLOBAL.URLBACKEND + '/letterinouts/f/skeluar?subject_contains=' + search + '&hruserpositions_to_ne=-1&hruserpositions_from=' + myuserpos + '&deleted_from=0&_limit=' + limit + '&_start=' + start +'&_sort=id%3ADESC'
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function countSKAllOpened(listQuery, myuserpos) {
  var search = listQuery.search

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''
  
  var uri = _GLOBAL.URLBACKEND + '/letterinouts/c/skeluar?subject_contains=' + search + '&hruserpositions_to_ne=-1&hruserpositions_from=' + myuserpos + '&deleted_from=0'
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function deleteLetterInOut_TO(id) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = {
    deleted_to: 1
  }
  // console.log(data)
  var uri = _GLOBAL.URLBACKEND + '/letterinouts/' + id
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'put',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function deleteLetterInOut_FROM(id) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = {
    deleted_from: 1
  }
  // console.log(data)
  var uri = _GLOBAL.URLBACKEND + '/letterinouts/' + id
  var response = request({
    url: uri,
    method: 'put',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function updateLetterInOut(data, id) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (!data) return null

  var uri = _GLOBAL.URLBACKEND + '/letterinouts/' + id
  var response = request({
    url: uri,
    method: 'put',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function realDeleteLetterInOut(id) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''

  var uri = _GLOBAL.URLBACKEND + '/letterinouts/' + id
  var response = request({
    url: uri,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}


export function insertLetterInOutDisposisi (data) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (!data) return null
  // console.log(data)

  var uri = _GLOBAL.URLBACKEND + '/letterinouts/insdispo'
  var response = request({
    url: uri,
    method: 'post',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function insertLetterInOutShare (data) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (!data) return null
  // console.log(data)

  var uri = _GLOBAL.URLBACKEND + '/letterinouts/insshare'
  var response = request({
    url: uri,
    method: 'post',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function insertLetterInOutComment (data) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (!data) return null
  // console.log(data)

  var uri = _GLOBAL.URLBACKEND + '/letterinouts/inscomment'
  var response = request({
    url: uri,
    method: 'post',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getSMByUposandLetterIDwithoutOpenSts(myuserpos, idletter) {

  var token = secureStorage.getItem('jwt')
  // console.log('token', token)
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/letterinouts?hruserpositions_to=' + myuserpos + '&id_letter='+ idletter +'&deleted_to=0&_limit=50&_sort=id%3ADESC'
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getSMByUposandLetterID(myuserpos, idletter) {

  var token = secureStorage.getItem('jwt')
  // console.log('token', token)
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/letterinouts?hruserpositions_to=' + myuserpos + '&id_letter='+ idletter +'&to_isOpen=0&deleted_to=0&_limit=10&_sort=id%3ADESC'
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getSMByUposandLetterIDhasDispo(myuserpos, idletter) {

  var token = secureStorage.getItem('jwt')
  // console.log('token', token)
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/letterinouts?hruserpositions_to=' + myuserpos + '&id_letter='+ idletter +'&deleted_to=0&_limit=10&_sort=id%3ADESC'
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getNewLetterRole(userid) {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/letterinouts/f/byuser?userid=' + userid
  var data = ''
  // console.log(uri)

  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

/** API Inbox/Outbox ==== */
