<template>
  <div class="dashboard-container">
    <component :is="currentRole"/>
  </div>
</template>

<script>

import adminDashboard from '@/views/dashboard/admin'
import direksiDashboard from '@/views/dashboard/direksi'
import kadivDashboard from '@/views/dashboard/kadiv'
import staffDashboard from '@/views/dashboard/staff'
import presdirDashboard from '@/views/dashboard/presdir'
import vpdDashboard from '@/views/dashboard/vpd'
import komiteDashboard from '@/views/dashboard/komite'

import secureStorage from '@/utils/secureStorage'

export default {
  name: 'Dashboard',
  components: { adminDashboard, direksiDashboard, kadivDashboard, staffDashboard, presdirDashboard, vpdDashboard, komiteDashboard},
  data() {
    return {
      currentRole: 'staffDashboard'
    }
  },
  created() {

    var currRole = secureStorage.getItem('currRole')
    if(currRole){
      var myroles = currRole.rolename
      
      if (myroles.includes('admin') || myroles.includes('developer')) {
        this.currentRole = 'adminDashboard'
      }else if(myroles.includes('presdir')){
        this.currentRole = 'presdirDashboard'
      }else if(myroles.includes('VP')){
        this.currentRole = 'vpdDashboard'
      }else if(myroles.includes('komite')){
        this.currentRole = 'komiteDashboard'
      }else if(myroles.includes('director') || myroles.includes('GM') || myroles.includes('manager')){
        this.currentRole = 'direksiDashboard'
      }else if(myroles.includes('kadiv') || myroles.includes('kabag')){
        this.currentRole = 'kadivDashboard'
      }else {
        this.currentRole = 'staffDashboard'
      }
    }else {
      this.currentRole = 'staffDashboard'
    }

    console.log(this.currentRole)
    
    
  }
}
</script>
