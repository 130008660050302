import request from '@/utils/request'
import { _GLOBAL } from '@/api/server'
import secureStorage from '@/utils/secureStorage'

/** API MasterAttachment */
export function getMasterAttachment(moduletype, id_letter) {
  // console.log(id_letter)
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/masterattachments?moduletypes=' + moduletype + '&id_module_data=' + id_letter + '&_sort=id%3AASC'
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function insertMasterAttachment(data) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (!data) return null
  // console.log(data)

  var uri = _GLOBAL.URLBACKEND + '/masterattachments'
  var response = request({
    url: uri,
    method: 'post',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  }).catch((err) => {
    return { 'id': 0, 'data': '', 'status': 'Data should be a unique please recheck the data.' }
  })
  // console.log(response)
  return response
}

export function deleteMasterAttachment(id) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''

  var uri = _GLOBAL.URLBACKEND + '/masterattachments/' + id
  var response = request({
    url: uri,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function updateMasterAttachment(data, id) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (!data) return null

  var uri = _GLOBAL.URLBACKEND + '/masterattachments/' + id
  var response = request({
    url: uri,
    method: 'put',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function countMasterAttachment(moduletype, id_letter) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''

  var uri = _GLOBAL.URLBACKEND + '/masterattachments/count?moduletypes=' + moduletype + '&id_module_data=' + id_letter
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function uploadMasterAttachment(refID, file) {
  // console.log('uploadAttachment')
  // console.log(refID)

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  const bodyFormData = new FormData()
  bodyFormData.append('refId', refID)
  bodyFormData.append('field', 'attachment')
  bodyFormData.append('ref', 'masterattachment')
  // bodyFormData.append('source', 'masterattachments')
  bodyFormData.append('path', 'masterattachment/attachment')
  // Add each file upload to the form data
  bodyFormData.append('files', file, file.name)

  var uri = _GLOBAL.URLBACKEND + '/upload'
  var response = request({
    url: uri,
    method: 'post',
    headers: {
      'Authorization': `Bearer ${token}`
    },
    data: bodyFormData,
    contentType: false,
    processData: false,
    crossDomain: true,
    async: true,
    mimeType: 'multipart/form-data'

  })
  // console.log(response)
  return response
}

export function deleteFileMasterAttachment(FileId) {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''

  var uri = _GLOBAL.URLBACKEND + '/upload/files/' + FileId
  var response = request({
    url: uri,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

/** API masterattachments ==== */

