import { render, staticRenderFns } from "./rssWebsite.vue?vue&type=template&id=60455112&"
import script from "./rssWebsite.vue?vue&type=script&lang=js&"
export * from "./rssWebsite.vue?vue&type=script&lang=js&"
import style0 from "./rssWebsite.vue?vue&type=style&index=0&id=60455112&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports